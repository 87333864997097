#tkb {
    display: none;
}

body {
    background: url('/img/background.webp') no-repeat center center fixed;
    background-size: cover;
    color: #e0e0e0;
    font-family: 'Roboto', sans-serif;
}

h3 {
    font-family: 'Pacifico', cursive;
    font-size: 2rem;
    color: #00ffcc;
    text-shadow: 4px 4px 0 #ad7979, -2px -2px 0 #000000, 2px -2px 0 #000000, -2px 2px 0 #000000, 2px 2px 0 #000000;
    margin-bottom: 1.5rem;
}

.card {
    background: rgba(0, 0, 0, 0.85);
    border: 2px solid #00ffcc;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.8);
}

.card-header {
    border-radius: 15px 15px 0 0 !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 3px solid #00ffcc;
    padding: 1rem;
}

.card-body {
    padding: 2.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0 0 15px 15px;
}

.form-control {
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid #00ffcc;
    color: #00ffcc;
}

.form-control:focus {
    border-color: #ff00ff;
    box-shadow: 0 0 12px #ff00ff;
}

label {
    color: #00ffcc;
    font-weight: bold;
}

button,
select,
input[type="text"] {
    transition: all 0.3s ease-in-out;
}

button {
    border: 2px solid #00ffcc;
    background-color: transparent;
    color: #00ffcc;
    font-weight: bold;
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

button:hover {
    transform: scale(1.1);
    background-color: #00ffcc;
    color: #000;
    box-shadow: 0 0 10px #ff00ff;
}

.btn-warning {
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid #00ffcc;
    color: #ffffff;
    font-weight: bold;
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.btn-warning:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: #00ffcc;
    color: #00ffcc;
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
}

.btn-warning:focus {
    outline: none;
    box-shadow: 0 0 12px #ff9800, 0 0 20px #ffc107;
}

.btn-warning:active {
    background-color: #ff9800;
    border-color: #ff5722;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.7) inset;
}

#thoikhoabieu {
    font-size: 1.2rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    font-family: 'Roboto', sans-serif;
}

th,
td {
    padding: 15px 20px;
    text-align: center;
    border-bottom: 1px solid #555;
}

thead th {
    background-color: #2a5298;
    color: #00ffcc;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

tbody tr {
    transition: background-color 0.3s ease;
}

tbody tr:nth-child(even) {
    background-color: #1e3c72;
}

tbody tr:nth-child(odd) {
    background-color: #142850;
}

tbody tr:hover {
    background-color: #00ffcc;
    color: #000;
    cursor: pointer;
}

tbody tr td:first-child {
    font-weight: bold;
    color: #00ffcc;
}

td.highlight {
    background-color: #00ffcc;
    color: #000;
    font-weight: bold;
}

.table-responsive {
    overflow-x: auto;
}

h3::before {
    content: "📅 ";
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

h3::after {
    content: " 🎓";
    font-size: 1.5rem;
    margin-left: 0.5rem;
}

@media only screen and (max-width: 768px) {

    th,
    td {
        padding: 10px 15px;
        font-size: 0.9rem;
    }

    h3 {
        font-size: 2rem;
    }

    .card-body {
        padding: 1.5rem;
    }

    button {
        padding: 0.5rem 1.5rem;
        font-size: 1rem;
    }
}

@media only screen and (max-width: 480px) {

    th,
    td {
        padding: 8px 10px;
        font-size: 0.8rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    .card-body {
        padding: 1rem;
    }

    button {
        padding: 0.4rem 1rem;
        font-size: 0.9rem;
    }
}